<template>
  <div class="page">
    <!-- <nav-bar title="训记限时邀新活动" :showBack="true" @leftclick="newAppBack"></nav-bar> -->
    <nav-bar @click-left="newAppBack" @click-right="inviteByMini" showRight title="训记限时邀新活动">
      <div slot="left" class="left-icon f36 row-center-center"><van-icon name="arrow-left" color="#fff" /></div>
      <div slot="leftScroll" class="left-scroll-icon f36 row-center-center"><van-icon name="arrow-left" color="#000" /></div>
      <img slot="right" class="right-icon" src="https://img.chaolu.com.cn/ACT/training-record/invite/Frame%201171275121%402x%20%281%29.png" alt="" />
      <img slot="rightScroll" class="right-icon" src="https://img.chaolu.com.cn/ACT/training-record/invite/Frame%201171275121%402x%20%282%29.png" alt="" />
    </nav-bar>

    <img @click="goRule" class="rule-icon" :style="{ transform: barHeight ? `translateY(${barHeight}px)` : '' }" src="https://img.chaolu.com.cn/ACT/training-record/invite/Group%2027692%402x.png" alt="" />

    <div class="main col-start-center">
      <img src="https://img.chaolu.com.cn/ACT/training-record/invite/01085.png" alt="" class="banner" />
      <div class="award-box col-between-center">
        <div class="award-list row-center-center">
          <div class="award-item fw6" @click="goAward('DURATION')">
            <p class="f22">鹿分收益<van-icon name="arrow" /></p>
            <p class="p1 f28">{{ awardInfo.rewardSendStat.sumDuration }}<span class="f20">鹿分</span></p>
          </div>
          <div class="award-item fw6" @click="goAward('EQUITY_CARD')">
            <p class="f22">获得权益卡<van-icon name="arrow" /></p>
            <p class="p1 f28">{{ awardInfo.rewardSendStat.equityCardCount }}<span class="f20">张</span></p>
          </div>
          <div class="award-item fw6" @click="goAward('VOUCHER')">
            <p class="f22">获得优惠券<van-icon name="arrow" /></p>
            <p class="p1 f28">{{ awardInfo.rewardSendStat.voucherCount }}<span class="f20">张</span></p>
          </div>
        </div>
        <div>
          <div @click="inviteByMini" class="invite-btn f34 fw6 row-center-center"><img src="https://img.chaolu.com.cn/ACT/training-record/invite/01081.png" alt="" class="w-icon" />立即邀请</div>
          <div class="btns row-between-center f28 fw6">
            <div @click="facetofaceShow" class="invite-btn type2 row-center-center"><img src="https://img.chaolu.com.cn/ACT/training-record/invite/01084.png" alt="" class="w-icon" />面对面邀请</div>
            <div @click="shareShow" class="invite-btn type2 row-center-center"><img src="https://img.chaolu.com.cn/ACT/training-record/invite/01082.png" alt="" class="w-icon" />分享海报</div>
          </div>
          <div class="my row-center-center">
            <p class="f24 fw6">我的邀请码：{{ awardInfo.inviteCode }}</p>
            <div @click="copyInviteCode" class="my-btn f22 row-center-center">复制</div>
            <div @click="isFillShow = true" class="my-btn f22 row-center-center">填写邀请码</div>
          </div>
        </div>
      </div>
      <!-- 邀请记录 -->
      <div v-if="inviteInfo.sum" class="invite-box">
        <div class="row-between-center">
          <p class="title f32 fw6">邀请记录</p>
          <p @click="goInvite" class="title-tip f24">{{ inviteInfo.invitingSum }}人邀请中，{{ inviteInfo.sum - inviteInfo.invitingSum }}人邀请成功<van-icon name="arrow" /></p>
        </div>
        <div class="invite-list row-start-center">
          <div class="invite-item f22 col-start-center" v-for="item in inviteInfo.userInfoList.slice(0, 5)" :key="item.headImg">
            <img :src="item.headImg" alt="" class="ovo" />
            <span :class="['sta', item.status === 1 ? 'success' : '']">{{ item.status === 1 ? '邀请成功' : '邀请中' }}</span>
          </div>
          <div @click="inviteByMini" v-if="inviteInfo.userInfoList.length < 5" class="invite-item f22 col-start-center">
            <img src="https://img.chaolu.com.cn/ACT/training-record/invite/add-icon.png" alt="" class="ovo" />
            <span class="sta black">立即邀请</span>
          </div>
        </div>
      </div>
      <!-- 限时任务 -->
      <div class="limit-box">
        <div class="row-between-center">
          <img src="https://img.chaolu.com.cn/ACT/training-record/invite/limit-title.png" alt="" class="limit-title">
        </div>
        <div class="limit-list">
          <div class="limit-item row-between-start" v-for="item in limitTask" :key="item.title">
            <img :src="item.icon" alt="" class="icon" />
            <div class="mid fw6 flex-auto">
              <p class="p1 f32" style="margin: 0 0 4px">{{ item.title }}</p>
              <p class="p2 f26 row-start-center"><img src="https://img.chaolu.com.cn/ACT/training-record/invite/icon-point.png" alt="" />+1000鹿分</p>
              <p class="f22 fw4" v-if="item.introduce">{{ item.introduce }}</p>
              <p class="p3 f22">{{ item.conditions }}</p>
            </div>
            <div class="right fw6 col-start-end">
              <p class="p4 f22">{{ item.rewardTotal }}</p>
              <!-- 1可领取 0不可领取 2已领取 -->
              <div @click="getLimitPrize(item)" :class="['btn f24 row-center-center', item.receiveStatus === 1 ? '' : 'grey']">{{ item.receiveStatus === 2 ? '已领取' : '领取' }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 邀请工具 -->
      <div class="tool-box" v-if="appTypeStr !== 'mini'">
        <div class="row-between-center">
          <p class="f32 fw6">邀请工具</p>
        </div>
        <p class="tip f24">好友「扫一扫」你分享的海报二维码，即可完成绑定</p>
        <div class="tool-list">
          <div class="tool-item">
            <div class="top row-between-start">
              <img src="https://img.chaolu.com.cn/ACT/training-record/invite/icon-grea.png" alt="" class="icon" />
              <div class="mid flex-auto">
                <div class="f32 fw6 row-start-center">分享自己任意的训练海报</div>
                <p class="p2 f22">每次训练结束即可生成，分享交流训练经验</p>
              </div>
              <div class="right fw6">
                <div @click="openTrainList" class="btn f24 row-center-center">去分享</div>
              </div>
            </div>
            <div class="bottom">
              <img src="https://img.chaolu.com.cn/ACT/training-record/invite/tool-1.png" alt="" />
            </div>
          </div>

          <div class="tool-item">
            <div class="top row-between-start">
              <img src="https://img.chaolu.com.cn/ACT/training-record/invite/icon-fan.png" alt="" class="icon" />
              <div class="mid flex-auto">
                <div class="f32 fw6 row-start-center">分享运动日历</div>
                <p class="p2 f22">分享自己的运动打卡记录</p>
              </div>
              <div class="right fw6">
                <div @click="shareTraining('3023')" class="btn f24 row-center-center">去分享</div>
              </div>
            </div>
            <div class="bottom">
              <img src="https://img.chaolu.com.cn/ACT/training-record/invite/tool-2.png" alt="" />
            </div>
          </div>

          <div class="tool-item">
            <div class="top row-between-start">
              <img src="https://img.chaolu.com.cn/ACT/training-record/invite/icon-bao.png" alt="" class="icon" />
              <div class="mid flex-auto">
                <div class="f32 fw6 row-start-center">分享周报/月报</div>
                <p class="p2 f22">长期坚持一定有收获，<br />分享自己的阶段成果</p>
              </div>
              <div class="right fw6 flex">
                <div @click="openWeek" class="btn f24 row-center-center">分享周报</div>
                <div @click="openMonth" class="btn f24 row-center-center" style="margin: 0 0 0 6px">分享月报</div>
              </div>
            </div>
            <div class="bottom">
              <img src="https://img.chaolu.com.cn/ACT/training-record/invite/tool-3.png" alt="" />
            </div>
          </div>

          <div class="tool-item">
            <div class="top row-between-start">
              <img src="https://img.chaolu.com.cn/ACT/training-record/invite/icon-im.png" alt="" class="icon" />
              <div class="mid flex-auto">
                <div class="f32 fw6 row-start-center">分享运动概要</div>
                <p class="p2 f22">今天上了什么课？小鹿对你说了什么</p>
              </div>
              <div class="right fw6">
                <div @click="shareTraining('3022')" class="btn f24 row-center-center">去分享</div>
              </div>
            </div>
            <div class="bottom">
              <img src="https://img.chaolu.com.cn/ACT/training-record/invite/tool-4.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-action-sheet v-model="isRecentlyShow" title="分享最近的训练" @close="isRecentlyShow = false" :closeable="false">
      <div class="recently-sheet col-start-center">
        <div class="train-list" v-if="recentlyList.length">
          <div class="train-item row-between-start" v-for="item in recentlyList" :key="item.exerciseId" :style="`background-image: url(${item.detailBackgroundImage})`">
            <div class="mask row-between-start">
              <div>
              <p class="f36 fw6">{{ item.exerciseTitle }}</p>
              <p class="p2 f24">{{ item.finishGroupNum }}组 · {{ item.motionNum }}个动作 · {{ item.sumKg }}kg</p>
              <p class="p3 f24">{{ item.dateStr }} {{ item.weekStr }}</p>
            </div>
            <div class="right fw6">
              <div @click="shareTraining('3025', item)" class="edit row-center-center f20">编辑&nbsp;<van-icon name="arrow" /></div>
              <div @click="shareTraining('3021', item)" class="share f24 row-center-center">生成海报分享</div>
            </div>
            </div>
          </div>
        </div>
        <empty text="暂无训记记录<br>快去开启训练吧～" v-else />
        <p class="f26 row-start-center">仅展示最近10条训记</p>
      </div>
    </van-action-sheet>
    <!-- 填写邀请码 -->
    <transition name="fade">
      <div class="ap-cover col-center-center" v-if="isFillShow" @touchmove.prevent>
        <div class="col-center-center">
          <div class="pop-usual col-center-center">
            <p class="f36 fw6">填写邀请码</p>
            <div class="usual-box white row-center-center">
              <van-field v-model="userNo" input-align="center" autofocus placeholder="请输入邀请码（TA的训记ID）" />
            </div>
            <div @click="checkInviteCode" class="usual-btn row-center-center f28 fw6">提交邀请码</div>
          </div>
          <img class="close" @click="isFillShow = false" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/inviter/share_close.png" alt="" />
        </div>
      </div>
    </transition>
    <!-- 校验接受邀请 -->
    <transition name="fade">
      <div class="ap-cover col-center-center" v-if="isCheckShow" @touchmove.prevent>
        <div class="col-center-center">
          <div class="pop-usual col-center-center">
            <p class="f36 fw6">确认接受TA的邀请吗？</p>
            <div class="usual-box col-center-center">
              <img :src="inviterObj.headImgUrl" alt="" class="ovo" />
              <p class="name f28 fw6">{{ inviterObj.nickName }}</p>
              <p class="id f24">ID:{{ inviterObj.userNo }}</p>
            </div>
            <div @click="acceptInvite" class="usual-btn row-center-center f28 fw6">接受邀请</div>

          </div>
          <img class="close" @click="isCheckShow = false" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/inviter/share_close.png" alt="" />
        </div>
      </div>
    </transition>

    <!-- 面对面邀请 -->
    <transition name="fade">
      <div class="ap-cover col-center-center" v-if="isFacetofaceShow" @touchmove.prevent>
        <div class="pop-content col-center-center">
          <div class="pop-code col-center-center">
            <img class="qr" :src="`data:image/png;base64,${userDetail.qrCode}`" alt="" />
            <div class="inviter row-center-center">
              <img :src="userDetail.headImg" alt="" class="ovo" />
              <p class="f26 fw6">{{ userDetail.nickName }}</p>
            </div>
            <div class="sun-tip f24">好友使用训记完成一次训练<br />你可得最高1000鹿分</div>
          </div>
          <img class="close" @click="isFacetofaceShow = false" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/inviter/share_close.png" alt="" />
        </div>
      </div>
    </transition>

    <!-- 分享海报 -->
    <common-share :value="isShareShow" :shareParams="shareParams" @close="isShareShow = false" @share-success="isShareShow = false" :shareItem="['posterfriend', 'postermessage', 'save']" :miniSharePoster="appTypeStr === 'mini'">
      <template slot="createDom">
        <div class="canvascss">
          <img src="https://img.chaolu.com.cn/ACT/training-record/invite/Group%201142813644%402x.png" class="canvascss_bg" />
          <div class="canvascss_left">
            <img :src="userDetail.headImg" />
          </div>
          <div class="canvascss_mid">
            <p class="canvascss_name f28 fw6 ellipsis">{{ userDetail.nickName }}</p>
            <p class="canvascss_tip f20">邀请你体验超鹿训记</p>
          </div>
          <div class="canvascss_right">
            <img v-if="userDetail.qrCode" :src="'data:image/png;base64,' + userDetail.qrCode" />
          </div>
        </div>
      </template>
    </common-share>

    <van-popup v-model="isWeekShow" position="bottom" safe-area-inset-bottom class="pop">
      <van-picker show-toolbar :default-index="weekDefaultIndex" :columns="weekColumns"  @change="weekChange" @confirm="weekConfirm" @cancel="isWeekShow = false" :title="pickerTitle" />
    </van-popup>
    <!-- 微信、小程序右上角分享提示 -->
    <van-overlay :show="isShareWxShow" :z-index="1500" @click="isShareWxShow = false">
      <div class="tip-box" @click.stop>
        <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/anniversary2021/share-mini.png" class="tip-img" />
      </div>
    </van-overlay>

    <accept-invite-popup :acceptResult="acceptResult" :userId="userId" :day="1" :isChooseShow.sync="isChooseShow" :isAcceptShow.sync="isAcceptShow"></accept-invite-popup>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { initBack, newAppBack, appOpenWeb, goAppPageByNumber, webAppShare, getBarHeight } from '@/lib/appMethod'
import navBar from '@/common/components/header'
import CommonShare from '@/components/commonShare/newShare'
import empty from './components/empty'
import acceptInvitePopup from './components/accept-invite-popup'
import { getParam } from '@/lib/utils'
import wx from 'weixin-js-sdk'

const webUrl = `${window.location.origin}/#/training-record/invite-accept`

export default {
  data() {
    return {
      barHeight: getBarHeight(),
      awardInfo: { rewardSendStat: {} },
      inviteInfo: { userInfoList: [] },
      limitTask: [],

      userDetail: {},
      shareParams: {
        title: '训记限时邀新活动',
        miniImage: 'https://img.chaolu.com.cn/ACT/training-record/invite/Mask%20group%402x.png',
        path: '',
        miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2', // 正式0，开发1，体验2
        url: 'url',
        multiple: '0.95',
        userName: 'gh_7f5b57b6a76e',
      },
      isRecentlyShow: false,
      isShareShow: false,
      isFacetofaceShow: false,
      userNo: '', // 填写的训记邀请码
      inviterObj: '', // 填写的邀请人信息
      acceptResult: {resultStatus: 1},
      isFillShow: false,
      isCheckShow: false,

      isAcceptShow: false,
      isChooseShow: false,

      recentlyList: [], // 近期训练列表

      isWeekShow: false,
      isShareWxShow: false,

      pickerTitle: '',
      weekColumns: [], // 周选择器
      weekDefaultIndex: 0, // 默认选择当天所在周
    }
  },
  mixins: [userMixin],
  components: {
    navBar,
    CommonShare,
    empty,
    acceptInvitePopup
  },
  async created() {
    await this.$getAllInfo()
    initBack()
    this.initQr()
    this.getAwardInfo()
    this.getInviteList()
    this.getLimitTask()
    this.clearActivityRewardReceiveViewStatus()

    this.miniShareConfig()
    this.countPoint('500', '502', '5028', this.userId)
  },
  methods: {
    newAppBack,
    getAwardInfo() {
      this.$axios.post(`${this.baseURLApp}/c/exercise/invite/index`, {userId: this.userId}).then((res) => {
        this.awardInfo = res.data
      })
    },
    getInviteList() {
      this.$axios.post(`${this.baseURLApp}/c/exercise/invite/recordStat`, {userId: this.userId}).then((res) => {
        this.inviteInfo = res.data
        this.inviteInfo.userInfoList = res.data.userInfoList || []
      })
    },
    getLimitTask() {
      this.$axios.post(`${this.baseURLApp}/c/exercise/invite/listTimeLimitTask`, {userId: this.userId}).then((res) => {
        this.limitTask = res.data
      })
    },
    getLimitPrize(item) {
      if (item.receiveStatus !== 1) return
      this.$axios.post(`${this.baseURLApp}/c/exercise/invite/receiveTimeLimitTask`, {userId: this.userId, timeLimitType: item.timeLimitType}).then((res) => {
        this.$toast('领取成功!')
        this.getLimitTask()
      })
    },
    clearActivityRewardReceiveViewStatus() {
      this.$axios.post(`${this.baseURLApp}/c/exercise/activity/clearActivityRewardReceiveViewStatus`, {userId: this.userId, activityCode: 'INVITE'}).then((res) => {

      })
    },
    checkInviteCode() {
      if (!this.userNo) {
        this.$toast('请输入邀请码')
        return
      }
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      this.$axios.post(`${this.baseURLApp}/c/user/getUserInfoByUserNo`, {
        userNo: this.userNo
      }).then((res) => {
        this.$toast.clear()
        this.inviterObj = res.data
        this.isFillShow = false
        this.userNo = ''
        this.isCheckShow = true
      })
    },
    acceptInvite() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      this.$axios.post(`${this.baseURLApp}/c/exercise/invite/acceptInvite`, {
        inviteId: this.inviterObj.userId,
        isNewUser: 0
      }).then((res) => {
        this.$toast.clear()
        this.acceptResult = res.data
        this.isCheckShow = false
        this.isAcceptShow = true
      })
    },
    goAward(type) {
      appOpenWeb('', `${window.location.origin}/#/training-record/invite-award?rewardType=${type}`)
    },
    goInvite() {
      appOpenWeb('', `${window.location.origin}/#/training-record/invite-record`)
    },
    goRule() {
      appOpenWeb('', `${window.location.origin}/#/training-record/invite-rule`)
    },

    miniShareConfig() {
      this.shareParams.path = `/pages/webView/index?webUrl=${webUrl}&inviteUserId=${this.userId}`
      wx.miniProgram.postMessage({
        data: {
          type: 'share',
          shareImage: this.shareParams.miniImage,
          shareTitle: this.shareParams.title,
          shareUrl: this.shareParams.path,
        },
      })
    },
    inviteByMini() {
      if (this.appTypeStr === 'mini') {
        this.isShareWxShow = true
        this.miniShareConfig()
      } else {
        this.shareParams.path = `/pages/webView/index?webUrl=${webUrl}&inviteUserId=${this.userId}`
        this.shareParams.image = this.shareParams.miniImage
        webAppShare('3', this.shareParams)
      }

      this.countPoint('500', '502', '5026', this.userId, 'PROMPTLY')
    },
    facetofaceShow() {
      this.isFacetofaceShow = true
      this.countPoint('500', '502', '5026', this.userId, 'FACE_TO_FACE')
    },
    shareShow() {
      this.isShareShow = true
      this.countPoint('500', '502', '5026', this.userId, 'SHARE_POSTER')
    },

    initQr() {
      this.$axios
        .post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
          token: this.token,
          userId: this.userId,
          width: 240,
          height: 240,
          webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
          scene: JSON.stringify({
            source: 'training-record-invite',
            inviteUserId: this.userId,
            webUrl,
          }),
        })
        .then((res) => {
          this.userDetail = res.data
        })
    },
    openTrainList() {
      this.getRecentlyTrainList()
      this.isRecentlyShow = true
    },
    getRecentlyTrainList() {
      this.$axios.post(`${this.baseURLApp}/c/user/schedule/pageFinishSchedule  `, { userId: this.userId }).then((res) => {
        this.recentlyList = res.data
      })
    },
    openWeek() {
      const arr1 = this.getAllMonths()
      const params = this.fixParams(arr1[0])
      const arr2 = this.getAllWeeksInMonth(params[0], params[1])
      this.getDefaultIndex(arr2)
      this.weekColumns = [{ values: arr1 }, { values: arr2 }]
      this.pickerTitle = '选择周'
      this.isWeekShow = true
    },
    openMonth() {
      const arr1 = this.getAllMonths()
      this.weekColumns = arr1
      this.weekDefaultIndex = 0
      this.pickerTitle = '选择月'
      this.isWeekShow = true
    },
    weekChange(picker, values) {
      console.log(values)
      const params = this.fixParams(values[0])
      const arr2 = this.getAllWeeksInMonth(params[0], params[1])
      picker.setColumnValues(1, arr2)
    },
    // 选择周范围或者月 原生交互需要2024-01-01的格式
    weekConfirm(val) {
      console.log('weekconfirm', val);

      let str = ''
      if (Array.isArray(val)) {
        str = `${val[0].slice(0, 4)}-${val[1].slice(0, 5).replace('/', '-')}`
      } else {
        const arr = this.fixParams(val)
        const month = ('0' + arr[1]).slice(-2)
        str = `${arr[0]}-${month}-01`
      }
      this.isWeekShow = false
      this.shareTraining('3024', {date: str})
    },
    // 获取当前月以及之前两年的月份
    getAllMonths() {
      const currentDate = new Date()
      const months = []
      for (let i = 0; i < 24; i++) {
        const year = currentDate.getFullYear()
        const month = currentDate.getMonth()
        months.push(`${year}.${month + 1}月`)
        currentDate.setMonth(currentDate.getMonth() - 1)
      }
      return months
    },
    fixParams(val) {
      const arr = val.slice(0, -1).split('.')
      return arr
    },
    // 获取本月所包含的周范围
    getAllWeeksInMonth(year, month) {
      let dates = []
      let currentDate = new Date(year, month - 1, 1) // 当月的第一天
      let currentDayOfWeek = currentDate.getDay() // 当月的第一天是周几
      // 如果当月的第一天不是周一，计算第一个周的起始日期
      if (currentDayOfWeek !== 1) {
        currentDate.setDate(currentDate.getDate() - (currentDayOfWeek === 0 ? 7 : currentDayOfWeek) + 1)
        console.log(currentDate, '---1');

        dates.push(`${currentDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' })}-${new Date(currentDate.getTime() + 6 * 24 * 60 * 60 * 1000).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' })}`)
        currentDate = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000)
        console.log(currentDate, '---2');
      }
      // 循环遍历每一周
      while (currentDate.getMonth() === month - 1) {
        let endDate = new Date(currentDate.getTime() + 6 * 24 * 60 * 60 * 1000) // 当前周的最后一天
        console.log(endDate, '---3');
        dates.push(`${currentDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' })}-${endDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' })}`)
        currentDate = new Date(endDate.getTime() + 1 * 24 * 60 * 60 * 1000) // 移动到下一周的第一天
        console.log(currentDate, '---4');
      }
      return dates
    },
    getDefaultIndex(arr) {
      const today = new Date().getDate()
      arr.map((item, index) => {
        let days = item.split('-') // 日期区间 ['12/12', '12/18']
        let dates = [days[0].slice(-2), days[1].slice(-2)]
        if (today < dates[0] && today < dates[1]) this.weekDefaultIndex = 0
        if (today > dates[0] && today > dates[1]) this.weekDefaultIndex = arr.length - 1
        if (today > dates[0] && today < dates[1]) this.weekDefaultIndex = index
      })
    },

    shareTraining(type, obj) {
      let params = {},
        date = new Date()
      const year = date.getFullYear()
      const month = ('0' + (date.getMonth() + 1)).slice(-2)
      const day = ('0' + date.getDate()).slice(-2)
      switch (type) {
        case '3021': // 训记
          params = { exerciseId: obj.exerciseId, exerciseType: obj.exerciseType, index: '1' }
          this.countPoint('500', '502', '5027', this.userId, 'EXERCISE_POSTER')
          break
        case '3022': // 概要
          params = { dateStr: `${year}-${month}-${day}` }
          this.countPoint('500', '502', '5027', this.userId, 'SPORTS_SUMMARY')
          break
        case '3023': // 运动日历
          params = { dateStr: `${year}-${month}-01` }
          this.countPoint('500', '502', '5027', this.userId, 'SPORTS_CALENDAR')
          break
        case '3024': // 周月报
          params = { dateStr: obj.date, dateMode: this.pickerTitle === '选择周' ? 'WEEK' : 'MONTH' }
          this.countPoint('500', '502', '5027', this.userId, 'EXERCISE_RECORD', this.pickerTitle === '选择周' ? 'WEEK' : 'MONTH')
          break
        case '3025': // 编辑训练
          params = { exerciseId: obj.exerciseId, exerciseType: obj.exerciseType }
          break
      }
      console.log(type, JSON.stringify(params));

      goAppPageByNumber(type, params)
    },
    copyInviteCode() {
      this.$copyText(this.awardInfo.inviteCode).then(
        (e) => {
          this.tips('已复制到剪贴板')
        },
        (e) => {
          this.tips('复制失败')
        }
      )
    },
  },
}
</script>

<style lang="less" scoped>
@import './components/popup.less';
.page {
  min-height: 100vh;
  * {
    box-sizing: border-box;
  }
  .main {
    background: linear-gradient(180deg, #ff2047 0%, #ff2047 400px, #fab3a7 750px, #fce6d3 1382px, #fce6d3 100%);
    .banner {
      width: 100%;
    }
    .award-box {
      width: 696px;
      height: 762px;
      background: url(https://img.chaolu.com.cn/ACT/training-record/invite/Group%2027763%402x.png) no-repeat left top / 100% auto;
      padding: 40px 0 26px;
      .award-list {
        width: 586px;
        .award-item {
          width: 33.33%;
          padding: 0 0 0 36px;
          color: #820322;
          &:not(:last-child) {
            border-right: 1px solid rgba(130, 3, 34, 0.2);
          }
          .p1 {
            margin: 8px 0 0;
          }
        }
      }
      .btns {
        margin: 26px 0 0;
      }
      .my {
        margin: 32px 0 0;
        color: #fff;
        .my-btn {
          padding: 0 16px;
          height: 34px;
          margin: 0 0 0 14px;
          background: rgba(255, 255, 255, 0.2);
          border-radius: 66px 66px 66px 66px;
          border: 2px solid rgba(255, 255, 255, 0.1);
        }
      }
      .invite-btn {
        width: 554px;
        height: 100px;
        background: linear-gradient(90deg, #ffcd84 0%, #ffefd2 100%);
        border-radius: 104px;
        color: #fa2d41;
        &.type2 {
          width: 258px;
          height: 76px;
          background: none;
          box-shadow: inset 0px 2 2px 0px rgba(255, 255, 255, 0.3);
          border: 2px solid rgba(254, 218, 188, 0.7);
          color: #fee3cc;
          .w-icon {
            width: 32px;
            margin: 0 8px 0 0;
          }
        }
        .w-icon {
          width: 44px;
          margin: 0 16px 0 0;
        }
      }
    }
    .invite-box {
      width: 710px;
      padding: 36px 32px;
      background: #ffffff;
      border-radius: 17px;
      margin: 24px 0 0;
      .title,
      .title-tip {
        color: #5c0218;
      }
      .invite-list {
        margin: 36px 0 0;
        .invite-item {
          &:not(:last-child) {
            margin: 0 46px 0 0;
          }
          .ovo {
            width: 92px;
            height: 92px;
            border-radius: 50%;
          }
          .sta {
            color: #267dff;
            margin: 18px 0 0;
            &.success {
              color: #fe3666;
            }
            &.black {
              color: #000;
            }
          }
        }
      }
    }
    .limit-box {
      width: 100%;
      background-color: #fff;
      padding: 36px 32px;
      border-radius: 17px 17px 0 0;
      margin: 24px 0 0;
      .limit-title {
        height: 34px;
      }
      .limit-list {
        margin: 4px 0 0;
        .limit-item {
          padding: 32px 0;
          .mid {
            padding: 14px 0 0 22px;
            .p1 {
              margin: 0 16px 0 0;
            }
            .p2 {
              color: #ff1d50;
              img {
                width: 36px;
                margin: 0 6px 0 0;
              }
            }
            .p3 {
              color: #267dff;
              margin: 8px 0 0;
            }
          }
          .right {
            padding: 22px 0 0;
            .p4 {
              color: #ff1d50;
              margin: 0 0 12px;
            }
          }
        }
      }
    }
    .tool-box {
      background-color: #fff;
      padding: 36px 32px;
      .tip {
        margin: 12px 0 0;
      }
      .tool-list {
        .tool-item {
          margin: 40px 0 0;
          .top {
            .mid {
              padding: 14px 0 0 22px;
              .p2 {
                color: #3c454e;
                margin: 4px 0 0;
              }
            }
            .right {
              padding: 22px 0 0;
            }
          }
          .bottom {
            margin: 20px 0 0;
            padding: 0 0 0 94px;
            img {
              width: 100%;
              padding: 0 0 40px;
              border-bottom: 1px solid #eeeeee;
            }
          }
        }
      }
    }
    .btn {
      min-width: 120px;
      padding: 0 24px;
      height: 60px;
      background: #ff1d50;
      border-radius: 76px 76px 76px 76px;
      color: #fff;
      &.grey {
        background: #dddddd;
        color: #9aa1a9;
      }
    }
    .icon {
      width: 72px;
      flex: none;
    }
  }
  //
  .recently-sheet {
    padding: 28px;
    .train-list {
      width: 100%;
    }
    .train-item {
      width: 100%;
      height: 280px;
      margin: 0 0 30px;
      color: #fff;
      border-radius: 12px;
      overflow: hidden;
      background-size: cover;
      background-position: center;
      position: relative;
      .mask {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .6);
        position: absolute;
        left: 0;
        top: 0;
        padding: 28px 32px;
      }
      .p2 {
        margin: 28px 0 0;
      }
      .p3 {
        margin: 28px 0 0;
      }
      .right {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        .edit {
          width: 96px;
          height: 46px;
          background: rgba(0, 0, 0, 0.4);
          border-radius: 66px;
          border: 2px solid rgba(255, 255, 255, 0.2);
        }
        .share {
          width: 192px;
          height: 60px;
          background: #ff1d50;
          border-radius: 76px 76px 76px 76px;
        }
      }
    }
  }

  .tip-box {
    position: relative;
    .tip-img {
      position: absolute;
      top: 7px;
      right: 130px;
      width: 300px;
      z-index: 99999;
      display: block;
    }
  }
  @bei: 1px;

.canvascss {
  position: fixed;
  bottom: -200%;
  // top: 0;
  width: @bei*600;
  height: @bei*1066;
  margin: 0 auto;
  box-sizing: border-box;

  .canvascss_bg {
    width: 100%;
    height: 100%;
  }

  .canvascss_left {
    position: absolute;
    left: @bei*26;
    bottom: @bei*46;
    z-index: 1;
    width: @bei*88;
    height: @bei*88;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .canvascss_mid {
    position: absolute;
    left: @bei*140;
    bottom: @bei*56;
    z-index: 1;
    width: @bei*252;
    .canvascss_tip {
      margin: 6px 0 0;
    }
  }

  .canvascss_right {
    position: absolute;
    right: @bei*34;
    bottom: @bei*28;
    width: @bei*120;
    height: @bei*120;
    overflow: hidden;
    background-size: 100% 100%;

    img {
      border-radius: @bei*8;
      width: 100%;
      height: 100%;
    }
  }
}
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .left-icon {
    width: 62px;
    height: 62px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .left-scroll-icon {
    width: 62px;
    height: 62px;
    border-radius: 50%;
  }
  .right-icon {
    width: 62px;
  }
  .rule-icon {
    width: 62px;
    position: absolute;
    top: calc(116px + env(safe-area-inset-top));
    right: 32px;
  }
}
</style>
