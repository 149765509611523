<template>
  <div class="container col-start-center">
    <nav-bar @click-left="newAppBack" title="超鹿训记诚邀体验">

    </nav-bar>
    <img class="banner" src="https://img.chaolu.com.cn/ACT/training-record/invite/Mask%20group%402x-2.png" alt="" />

    <div class="invite-box col-between-center">
      <div class="row-center-center">
        <img :src="inviteInfo.userHeadImg" alt="" class="ovo" />
        <div>
          <p class="f28 fw6">{{ inviteInfo.userName }}</p>
          <p class="f24">邀请你来使用超鹿训记</p>
        </div>
      </div>
      <div @click="acceptInvite" class="btn f32 fw6 row-center-center">{{ inviteInfo.status === 0 ? '接收邀请，领取奖励' : '接受邀请成功，使用训记获得奖励' }}</div>
    </div>
    <img class="title" src="https://img.chaolu.com.cn/ACT/training-record/invite/Group%201142813631%402x.png" alt="" />
    <div @click="playVideo" class="play-area row-center-center">
      <img src="http://img.chaolu.com.cn/MINI/icon/dongtai-play.png" alt="" class="icon" />
    </div>

    <!-- 讲解视频 -->
    <van-popup v-model="isVideoShow" :overlay="false" style="width: 100%; height: 100%; background: #000">
      <div class="popup-con col-center-center">
        <video
          @click="pause"
          ref="videoPlayer"
          class="video-js vjs-big-play-centered"
          playsinline="true"
          autoplay
          controls
          webkit-playsinline="true"
          x-webkit-airplay="true"
          x5-video-player-type="h5-page"
          x5-video-player-fullscreen="false"
          x5-video-orientation="portraint"
          style="object-fit: cover; width: 100%; height: 90vh"
          src="https://video.chaolu.com.cn/e0606f77ac9471efbf985017e1f80102/b3c677e85fc108d096d40e25fda3c8d8-hd-nbv1.mp4" />
        <van-icon @click="videoClose" class="icon" color="#fff" size="28" name="cross" />
        <img v-show="!isPlay" @click="play" class="play-btn" src="http://img.chaolu.com.cn/MINI/icon/dongtai-play.png" alt="" />
      </div>
    </van-popup>

    <accept-invite-popup :acceptResult="acceptResult" :userId="userId" :day="1" :isChooseShow.sync="isChooseShow" :isAcceptShow.sync="isAcceptShow"></accept-invite-popup>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { appOpenWeb, newAppBack } from '@/lib/appMethod'
import { getParam } from '@/lib/utils'
import navBar from '@/common/components/header'
import acceptInvitePopup from './components/accept-invite-popup'

export default {
  mixins: [userMixin],
  components: {
    navBar,
    acceptInvitePopup
  },
  data() {
    return {
      isVideoShow: false,
      isPlay: true,

      isAcceptShow: false,
      isChooseShow: false,
      inviteUserId: '',
      inviteInfo: {},
      acceptResult: {},
    }
  },
  async created() {
    await this.$getAllInfo()
    const p = getParam()
    this.inviteUserId = p.inviteUserId || p.inviter
    this.getInviteInfo()
  },

  methods: {
    newAppBack,
    playVideo() {
      this.isVideoShow = true
      this.play()
    },
    videoClose() {
      this.pause()
      this.isVideoShow = false
    },
    play() {
      this.isPlay = true
      this.$refs.videoPlayer.play()
    },
    pause() {
      this.isPlay = false
      this.$refs.videoPlayer.pause()
    },
    copyLink() {
      this.$copyText('http://a.app.qq.com/o/simple.jsp?pkgname=com.chaolu.app').then(
        (e) => {
          this.tips('已复制，打开浏览器下载')
        },
        (e) => {
          this.tips('复制失败')
        }
      )
    },
    getInviteInfo() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      this.$axios.post(`${this.baseURLApp}/c/exercise/invite/queryInviteInfo`, {
        userId: this.userId,
        inviteId: this.inviteUserId,
        isNewUser: 0
      }).then((res) => {
        this.$toast.clear()
        this.inviteInfo = res.data
      })
    },
    acceptInvite() {
      if (this.inviteInfo.status !== 0) {
        this.goTrainingIntroduction()
        return
      }
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })

      this.$axios.post(`${this.baseURLApp}/c/exercise/invite/acceptInvite`, {
        inviteId: this.inviteUserId,
        isNewUser: 0
      }).then((res) => {
        this.$toast.clear()
        this.acceptResult = res.data
        this.isCheckShow = false
        this.isAcceptShow = true
        this.getInviteInfo()
      })
    },
    goTrainingIntroduction() {
      appOpenWeb('', `${window.location.origin}/#/training-record/introduction`)
    },
  },
}
</script>
<style lang="less" scoped>
*::-webkit-scrollbar {
  display: none;
}
.container {
  background: linear-gradient(180deg, #ff2047 0%, #ff2047 100px, #fab3a7 600px, #fce6d3 800px, #fce6d3 100%);
  min-height: 100vh;
  * {
    box-sizing: border-box;
  }
  .title {
    height: 68px;
    margin: 32px 0 0;
  }
  .play-area {
    width: 670px;
    height: 329px;
    border-radius: 16px 16px 16px 16px;
    background: url(https://img.chaolu.com.cn/exercise/config/useInstruction/cover01.png) no-repeat left top / 100% auto;
    margin: 20px 0 0;
    .icon {
      width: 80px;
    }
  }
  .banner {
    width: 100%;
  }
  .invite-box {
    width: 696px;
    height: 742px;
    background: url(https://img.chaolu.com.cn/ACT/training-record/invite/Group%201142813561%402x.png) no-repeat left top / 100% auto;
    padding: 32px 0 62px;
    .ovo {
      width: 68px;
      border-radius: 50%;
      margin: 0 32px 0 0;
    }
    .btn {
      width: 556px;
      height: 100px;
      background: linear-gradient(90deg, #ffcd84 0%, #ffefd2 100%);
      border-radius: 104px 104px 104px 104px;
      color: #a6371b;
    }
  }
}
/deep/.van-popup {
  .popup-con {
    height: 100vh;
    box-sizing: border-box;
    .mid {
      width: 100%;
      height: 90vh !important;
      position: relative;
    }
    .icon {
      position: absolute;
      top: calc(40px + env(safe-area-inset-top));
      left: 40px;
    }
    .play-btn {
      width: 120px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 999;
    }
  }
}
</style>
