<template>
  <div class="empty col-center-center">
    <img src="https://img.chaolu.com.cn/ACT/training-record/invite/1empty.png" alt="" />
    <div v-html="text"></div>
  </div>
</template>
<script>
export default {
  props: ['text']
}
</script>

<style lang="less" scoped>
.empty {
  height: 66vh;

  img {
    width: 240px;
  }

  div {
    text-align: center;
    margin-top: 20px;
    font-size: 28px;
    line-height: 40px;
    color: #000;
  }
}
</style>
