<template>
  <div :class="['page', appTypeStr === 'mini' ? 'mini' : '']">
    <nav-bar headerTitle="邀请记录" :showBack="true" :header-back="newAppBack"></nav-bar>
    <div :class="['tip flex f22 ', appTypeStr === 'mini' ? 'mini' : '']">
      <div class="mr8 mt1">
        <van-icon name="info-o" />
      </div>
      <div>
        <p>绑定关系可维持30天，到期自动解绑；</p>
        <p>优惠券及权益卡需手动领取。</p>
      </div>
    </div>
    <div class="list" v-if="awardList.length">
      <div class="item row-start-start" v-for="item in awardList" :key="item.userId">
        <img :src="item.headImg" alt="" class="ovo" />
        <div class="right flex-auto">
          <p class="name f28 fw6 row-start-center"><span v-if="item.newUserFlag" class="tag row-center-center f20">新用户</span>{{ item.name }}</p>
          <p class="f22 date">绑定日期：{{ item.bindTime }}</p>
          <div class="detail flex">
            <div v-if="item.useExerciseBubbleList" class="detail-item">
              <p class="f22">好友使用训记</p>
              <div :class="['status row-center-center f20 fw6', item.useExerciseFlag === 1 ? 'active' : '']">{{ item.useExerciseFlag === 1 ? '已使用训记' : '待使用训记'}}</div>
              <p v-for="bubble in item.useExerciseBubbleList" :key="bubble" class="p2 f22 fw6 row-start-center"><img :src="bubble.icon" alt="" />{{ bubble.text }}</p>
            </div>
            <div v-if="item.inDoorBubbleList" class="detail-item">
              <p class="f22">好友进馆</p>
              <div :class="['status row-center-center f20 fw6', item.indoorFlag === 1 ? 'active' : '']">{{ item.indoorFlag === 1 ? '已进馆' : '待进馆'}}</div>
              <p v-for="bubble in item.inDoorBubbleList" :key="bubble" class="p2 f22 fw6 row-start-center"><img :src="bubble.icon" alt="" />{{ bubble.text }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <empty text="暂无邀请记录" v-else />
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { hideAppBackBtn, initBack, newAppBack } from '@/lib/appMethod'
import navBar from '@/components/nav-bar/nav-bar'
import empty from './components/empty'
import { getParam } from '@/lib/utils'

export default {
  data() {
    return {
      awardList: [],
      params: {
        pageNum: 1,
        pageSize: 100,
        userId: ''
      },
    }
  },
  mixins: [userMixin],
  components: {
    navBar,
    empty,
  },
  async created() {
    initBack()
    await this.$getAllInfo()

    this.params.userId = this.userId
    this.getAwardList()
  },
  methods: {
    newAppBack,
    getAwardList() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      this.$axios
        .post(`${this.baseURLApp}/c/exercise/invite/pageRecord`, this.params)
        .then((res) => {
          this.awardList = res.data
          this.$toast.clear()
        })
    },
  },
}
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.page {
  padding: calc(140px + env(safe-area-inset-top)) 0 0;
  &.mini {
    padding: 0;
  }
  min-height: 100vh;
  .tip {
    width: 100%;
    // height: 72px;
    padding: 10px 32px;
    background: #f9fafb;
    position: sticky;
    top: calc(100px + env(safe-area-inset-top));
    left: 0;
    &.mini {
      top: 0;
    }
  }
  .list {
    .item {
      padding: 32px 32px 0;
      &:not(:last-child) {
        .right {
          border-bottom: 2px solid #EEEEEE;
        }
      }
      .ovo {
        width: 88px;
        height: 88px;
        border-radius: 50%;
        margin: 0 24px 0 0;
      }
      .right {
        padding: 0 0 32px;
      }
      .tag {
        padding: 0 6px;
        height: 32px;
        background: #ff1d50;
        border-radius: 4px;
        color: #fff;
        margin: 0 8px 0 0;
      }
      .name {
        color: #000;
      }
      .date {
        margin: 8px 0 0;
        color: #6C727A;
      }
      .award {
        color: #ff1d50;
      }
      .btn {
        width: 136px;
        height: 60px;
        background: #ff1d50;
        border-radius: 76px 76px 76px 76px;
        color: #fff;
        margin: 8px 0 0;
        &.get {
          background: #dddddd;
          color: #9aa1a9;
        }
      }
      .detail {
        width: 100%;
        padding: 24px;
        margin: 12px 0 0;
        background: #f7f7f7;
        border-radius: 12px;
        .detail-item {
          width: 50%;
          .p2 {
              color: #ff1d50;
              margin: 8px 0 0;
              img {
                width: 32px;
                margin: 0 2px 0 0;
              }
            }
            .status {
              padding: 0 8px;
              height: 32px;
              background: rgba(38,125,255,0.1);
              border-radius: 6px 6px 6px 6px;
              color: #267DFF;
              margin: 12px 0 0;
              width: fit-content;
              &.active {
                background: #FFEDE9;
                color: #FF1D50;
              }
            }
        }
      }
    }
  }
}
</style>
