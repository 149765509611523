<template>
  <div>
    <!-- 确认接受ta的邀请 -->
    <transition name="fade">
      <div class="ap-cover col-center-center" v-if="isAcceptShow" @touchmove.prevent>
        <div class="col-center-center">
          <div class="pop-usual col-center-center">
            <p v-if="acceptResult.resultStatus === 1" class="f36 fw6"><van-icon color="#FF1D50" name="checked" /> 接受邀请成功</p>
            <p v-if="acceptResult.resultStatus !== 1" class="f36 fw6">接受邀请失败</p>
            <div class="usual-box col-center-center">
              <img v-if="acceptResult.resultStatus === 1" :src="acceptResult.userInfo.userHeadImg" alt="" class="ovo" />
              <p v-if="acceptResult.resultStatus === 1" class="id f26">
                您已成功接受了<span style="color: #ff1d50">{{ acceptResult.userInfo.userName }}</span
                >的邀请
              </p>
              <p v-if="acceptResult.resultStatus === 2" class="id f26">{{ acceptResult.failReason }}</p>
              <p v-if="acceptResult.resultStatus === 3" class="id f26">{{ acceptResult.failReason }}</p>
              <p v-if="acceptResult.resultStatus === 4" class="id f26">
                您已经接受了<span style="color: #ff1d50">{{ `${acceptResult.userInfo.userName}（ID: ${acceptResult.userInfo.userId}）` }}</span
                >的邀请，不可以再次接受邀请
              </p>
            </div>

            <div v-if="acceptResult.resultStatus === 1" @click="goTrainingIntroduction" class="usual-btn row-center-center f28 fw6">去使用训记获得奖励</div>
            <div v-if="acceptResult.resultStatus === 2" @click="close" class="usual-btn row-center-center f28 fw6">我知道了</div>
            <div v-if="acceptResult.resultStatus === 3" @click="goTrainingActivity" class="usual-btn row-center-center f28 fw6">立即参与</div>
            <div v-if="acceptResult.resultStatus === 4" @click="goTrainingActivity" class="usual-btn row-center-center f28 fw6">我知道了</div>
          </div>
          <img class="close" @click="judgeClose" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/inviter/share_close.png" alt="" />
        </div>
      </div>
    </transition>
    <!-- 惊喜红包 1天卡-->

    <div class="ap-cover col-center-center" v-if="isRedbagShow || isDeerpointShow" @touchmove.prevent></div>
    <transition name="bounce">
      <div class="ap-cover transparent col-center-center" v-if="isRedbagShow" @touchmove.prevent>
        <div class="col-center-center" v-if="isRedbagShow">
          <div class="pop-redbag col-start-center">
            <div class="col-start-center fw6">
              <p class="f32">恭喜您获得</p>
              <p class="p2 f48">超鹿月卡{{ day === 1 ? 1 : 3 }}天</p>
              <p class="p3 f32">领取后可免费来超鹿运动哦</p>
            </div>
            <div @click="openChoose" class="btn row-center-center f28 fw6">立即领取</div>
          </div>
          <img class="close" @click="judgeDeerpoint" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/inviter/share_close.png" alt="" />
        </div>
      </div>
    </transition>

    <!-- 惊喜红包鹿分 -->
    <transition name="bounce">
      <div class="ap-cover transparent col-center-center" v-if="isDeerpointShow"  @touchmove.prevent>
        <div class="col-center-center">
          <div class="pop-redbag deerpoint col-start-center">
            <div class="col-start-center fw6">
              <p class="f32">恭喜您即将获得</p>
              <div class="p2 rii f64 fw6 row-start-center">
                <p>1000</p>
                &nbsp;
                <div>
                  <p class="tag f20 row-center-center">可兑换5元礼品卡</p>
                  <p class="f28">鹿分</p>
                </div>
              </div>
              <p class="p3 f32">到任意超鹿门店打卡即可获得奖励</p>
            </div>
            <div @click="goTrainingActivity" class="btn row-center-center f28 fw6">查看活动详情</div>
          </div>
          <img class="close" @click="isDeerpointShow = false" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/inviter/share_close.png" alt="" />
        </div>
      </div>
    </transition>

    <!-- 选择城市 -->
    <transition name="fade">
      <div class="ap-cover col-center-center" v-if="isChooseShow" @touchmove.prevent>
        <div class="col-center-center">
          <div class="pop-choose col-center-center">
            <p class="title f40 fw6">恭喜您即将获得</p>
            <div class="input-box col-center-center">
              <img class="gift" :src="`https://img.chaolu.com.cn/ACT/invite-gift/reward${day === 1 ? '-1' : ''}.png`" />
              <p class="f26">请选择使用城市<span style="color: #9aa1a9">（选择后无法退改）</span></p>
              <div class="select row-between-center" @click="isCityShow = true">
                <p class="f28 fw6">{{ getCityName() }}</p>
                <van-icon name="arrow" />
              </div>
            </div>
            <div @click="sure" class="choose-btn row-center-center f28 fw6">确认领取</div>
          </div>
          <img class="close" @click="close" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/inviter/share_close.png" alt="" />
        </div>
      </div>
    </transition>
    <van-popup v-model="isCityShow" position="bottom" safe-area-inset-bottom class="pop">
      <van-picker title="选择城市" :columns="cityList" @confirm="onConfirm" @cancel="isCityShow = false" show-toolbar />
    </van-popup>
  </div>
</template>
<script>
import { appOpenWeb } from '@/lib/appMethod'

export default {
  // day表示1天卡或者3天卡 走不同的流程
  // 1天卡领取比较复杂 调用固定接口或者对应的参数
  props: ['day', 'acceptResult', 'isAcceptShow', 'isChooseShow', 'userId'],
  data() {
    return {
      isRedbagShow: false,
      isDeerpointShow: false,
      isCityShow: false,
      cityList: [],
      selectCityItem: undefined,

      onedayEquityCardParams: {}, // 一天卡固定参数
    }
  },
  watch: {
    isChooseShow(v) {
      if (v && this.cityList.length === 0) {
        this.getCityList()
      }
    },
    acceptResult(v) {
      if (v.isReceiveInviteReward) {
        this.isRedbagShow = true
      } else if (v.duration > 0) {
        this.isDeerpointShow = true
      }
    },
  },

  methods: {
    getCityList() {
      this.day === 1 ? this.getOnedayEquityCardParams() : null
      this.$axios.post(`${this.baseURLApp}/scheduleLesson/cityList`, { userId: this.userId }).then((res) => {
        const cityList = res.data.map((d) => {
          const model = {
            text: d.areaName,
            value: d.cityId,
          }
          if (this.cityId == d.cityId) {
            this.selectCityItem = model
          }
          return model
        })
        this.cityList = cityList
      })
    },
    getCityName() {
      if (this.selectCityItem) {
        return this.selectCityItem.text
      }
      return '请选择'
    },
    onConfirm(item) {
      this.isCityShow = false
      this.selectCityItem = item
    },
    sure() {
      if (!this.selectCityItem) {
        this.$toast('请选择城市')
        return
      }
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      // 1天卡
      if (this.day === 1) {
        const curSku = this.onedayEquityCardParams.skus.find((item) => item.description === this.selectCityItem.text)

        this.$axios
          .post(this.baseURLApp + '/venue/invitation/manualReceive', {
            userId: this.userId,
            cityId: -1,
            venueId: null,
            rewardId: this.onedayEquityCardParams.rewardId,
            sku: curSku.targetId,
            inviteType: 'NEW_USER_INVITE',
            inviteRewardsType: 'ACCEPT_NEW_USER_INVITE',
            hasWx: true,
            token: null,
          })
          .then((res) => {
            this.$toast('领取成功~')
            this.judgeDeerpoint()
          })
      } else {
        this.$axios
          .post(this.baseURLApp + '/invitation/myinvite/myreward/receive', {
            userId: this.userId,
            rewardId: this.acceptResult.equityCardRewardId,
            cityId: this.selectCityItem.value,
          })
          .then((res) => {
            this.$toast('领取成功~')
            this.judgeDeerpoint()
          })
      }
    },
    goTrainingActivity() {
      appOpenWeb('', `${window.location.origin}/#/training-record/invite`)
    },
    goTrainingIntroduction() {
      appOpenWeb('', `${window.location.origin}/#/training-record/introduction`)
    },
    getOnedayEquityCardParams() {
      // 入参都是固定值 不懂问文彩
      this.$axios
        .post(`${this.baseURLApp}/venue/invitation/manualReceiveDetail`, {
          userId: this.userId,
          cityId: -1,
          venueId: null,
          rewardId: null,
          inviteType: 'NEW_USER_INVITE',
          inviteRewardsType: 'ACCEPT_NEW_USER_INVITE',
          hasWx: true,
          token: null,
        })
        .then((res) => {
          this.onedayEquityCardParams = res.data.rewardMap[1][0]
          console.log(this.onedayEquityCardParams)
        })
    },
    openChoose() {
      this.$emit('update:isChooseShow', true)
    },
    judgeDeerpoint() {
      this.isRedbagShow = false
      this.close()
      if (this.acceptResult.duration > 0) {
        this.isDeerpointShow = true
      }
    },
    judgeClose() {
      switch (this.acceptResult.resultStatus) {
        case 1:
          this.goTrainingIntroduction()
          break
        case 3:
        case 4:
          this.goTrainingActivity()
          break
        default:
          this.close()
          break
      }
      this.close()
    },
    close() {
      this.$emit('update:isAcceptShow', false)
      this.$emit('update:isChooseShow', false)
    },
  },
}
</script>

<style lang="less" scoped>
@import './popup.less';
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.bounce-enter-active {
  animation: bounce-in 0.7s;
}
.bounce-leave-active {
  transform: scale(0);
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
