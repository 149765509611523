<template>
  <div class="page-rooter-fff">
    <nav-bar headerTitle="奖励明细" :showBack="true" :header-back="newAppBack"></nav-bar>
    <div class="award-box">
      <div class="award-list row-center-center">
        <div class="award-item fw6">
          <p class="f24 row-start-center"><img src="https://img.chaolu.com.cn/ACT/training-record/invite/icon-point.png" />鹿分收益</p>
          <p class="p1 f36">{{ awardInfo.sumDuration }}<span class="f22">鹿分</span></p>
        </div>
        <div class="award-item fw6">
          <p class="f24 row-start-center"><img src="https://img.chaolu.com.cn/ACT/training-record/invite/icon-equity.png" />获得权益卡</p>
          <p class="p1 f36">{{ awardInfo.equityCardCount }}<span class="f22">张</span></p>
        </div>
        <div class="award-item fw6">
          <p class="f24 row-start-center"><img src="https://img.chaolu.com.cn/ACT/training-record/invite/icon-coupon.png" />获得优惠券</p>
          <p class="p1 f36">{{ awardInfo.voucherCount }}<span class="f22">张</span></p>
        </div>
      </div>
    </div>
    <van-tabs border swipeable v-model="params.rewardType" title-active-color="#242831" title-inactive-color="#6C727A" color="#242831" line-height="2px" line-width="21px" @change="changeTab">
      <van-tab :title="tab.name" :name="tab.rewardType" v-for="tab in tabList" :key="tab.rewardType">
        <div class="list" v-if="awardList.length">
          <div class="award-item row-between-center" v-for="item in awardList" :key="item.rewardSendTraceNo">
            <div class="row-start-center">
              <img :src="item.headImg" alt="" class="ovo">
              <div>
                <p class="name f28 fw6 row-start-center ellipsis"><span v-if="item.newUserFlag" class="tag row-center-center f20">新用户</span>{{ item.name }}</p>
                <p class="f22 date">{{ item.sendDate }} {{ item.sendReason }}</p>
              </div>
            </div>
            <div class="col-center-center">
              <p class="award f22 fw6">{{ item.rewardContent }}</p>
              <div v-if="[0, 1].includes(item.rewardStatus)" @click="acceptAward(tab.rewardType, item)" :class="['btn row-center-center f22 fw6', item.rewardStatus === 1 ? 'get' : '']">{{ item.rewardStatus === 1 ? '已领取' : '领取奖励'}}</div>
            </div>
          </div>
        </div>
        <empty :text="tab.empty" v-else />
      </van-tab>

    </van-tabs>

    <accept-invite-popup :acceptResult="acceptResult" :userId="userId" :isChooseShow.sync="isChooseShow"></accept-invite-popup>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { initBack, newAppBack } from '@/lib/appMethod'
import navBar from '@/components/nav-bar/nav-bar'
import empty from './components/empty'
import { getParam } from '@/lib/utils'
import acceptInvitePopup from './components/accept-invite-popup'

export default {
  data() {
    return {
      tabList: [{name: '鹿分', rewardType: 'DURATION', empty: '暂未获得鹿分奖励'}, {name: '权益卡', rewardType: 'EQUITY_CARD', empty: '暂未获得权益卡奖励'}, {name: '优惠券', rewardType: 'VOUCHER', empty: '暂未获得优惠券奖励'}],
      params: {
        pageNum: 1,
        pageSize: 100,
        rewardType: ''
      },
      awardList: [],
      awardInfo: {},

      acceptResult: {},
      isChooseShow: false
    }
  },
  mixins: [userMixin],
  components: {
    navBar, empty, acceptInvitePopup
  },
  async created() {
    await this.$getAllInfo()
    initBack()

    this.getAwardInfo()

    this.params.rewardType = getParam().rewardType

    this.getAwardList()
  },
  methods: {
    newAppBack,
    getAwardInfo() {
      this.$axios
        .post(`${this.baseURLApp}/c/exercise/invite/index`, {userId: this.userId})
        .then((res) => {
          this.awardInfo = res.data.rewardSendStat
        })
    },
    getAwardList() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      this.$axios
        .post(`${this.baseURLApp}/c/exercise/invite/rewardSendRecord`, {userId: this.userId, ...this.params})
        .then((res) => {
          this.awardList = res.data
          this.$toast.clear()
        })
    },
    changeTab(v) {
      this.params.rewardType = v
      this.getAwardList()
    },
    acceptAward(type, item) {
      if (item.rewardStatus !== 0) return
      switch (type) {
        case 'VOUCHER':
        this.getVoucher(item)
          break;
        case 'EQUITY_CARD':
          this.acceptResult = item
          this.isChooseShow = true
          break;
      }
    },
    getVoucher(item) {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      this.$axios
        .post(`${this.baseURLApp}/c/exercise/activity/receiveReward`, {traceNo: item.traceNo})
        .then((res) => {
          this.getAwardList()
          this.$toast('领取成功~')
        })
    }
  },
}
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.page-rooter-fff {
  background: #F2F3F5;
  background: linear-gradient( 180deg, #FFFFFF 130px,  #F5F5F5 380px);
  padding: calc(120px + env(safe-area-inset-top)) 0 0;
  .award-box {
    width: 710px;
    height: 194px;
    background: linear-gradient( 145deg, #FF4F70 0%, #FF3357 100%);
    padding: 54px 0 50px;
    border-radius: 14px;
    margin: 0 auto;
    .award-list {
      width: 100%;
      .award-item {
        width: 33.33%;
        padding: 0 0 0 36px;
        color: #fff;
        &:not(:last-child) {
          border-right: 1px solid rgba(255, 255, 255, 0.3);
        }
        img {
          height: 32px;
          margin: 0 4px 0 0;
        }
        .p1 {
          margin: 16px 0 0;
        }
      }
    }
  }
  .van-tabs {
    margin: 20px 0 0;
    border-radius: 24px 24px 0 0;
    background: #fff;
    overflow: hidden;
    .award-item {
      padding: 36px 32px;
      .ovo {
        width: 88px;
        height: 88px;
        border-radius: 50%;
        margin: 0 24px 0 0;
      }
      .tag {
        padding: 0 6px;
        height: 32px;
        background: #FF1D50;
        border-radius: 4px;
        color: #fff;
        margin: 0 8px 0 0;
      }
      .name {
        color: #000;
      }
      .date {
        margin: 8px 0 0;
      }
      .award {
        color: #FF1D50;
      }
      .btn {
        width: 136px;
        height: 60px;
        background: #FF1D50;
        border-radius: 76px 76px 76px 76px;
        color: #fff;
        margin: 8px 0 0;
        &.get {
          background: #DDDDDD;
          color: #9AA1A9;
        }
      }
    }
  }

}
</style>
